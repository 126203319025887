import {
  createAction,
  handleAggregatorResponse,
} from '@wix/communities-blog-client-common';
import { loadTranslations } from '../../services/translations-getter';

const FREEZED_TRANSLATIONS_BASE_URL =
  'https://static.parastorage.com/services/communities-blog-translations/1.3344.0/';

export const FETCH_TRANSLATIONS_REQUEST = 'posts/FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'posts/FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILURE = 'posts/FETCH_TRANSLATIONS_FAILURE';

export const fetchTranslationsRequest = createAction(
  FETCH_TRANSLATIONS_REQUEST,
);
export const fetchTranslationsSuccess = createAction(
  FETCH_TRANSLATIONS_SUCCESS,
);
export const fetchTranslationsFailure = createAction(
  FETCH_TRANSLATIONS_FAILURE,
);

function fetchTranslationsAction(
  language,
  translationsName = 'main',
  useICU = false,
) {
  return (_dispatch, _getState, { appParams, request }) => {
    return loadTranslations({
      request,
      baseUrl: useICU
        ? appParams.baseUrls.translationsBaseUrl
        : FREEZED_TRANSLATIONS_BASE_URL,
      translationsName,
      language,
    });
  };
}

const createFetchTranslations =
  (action) =>
  (...args) => {
    return (dispatch) => {
      dispatch(fetchTranslationsRequest());

      const translationsPromise = dispatch(action(...args));
      return translationsPromise
        .then(
          (body) => dispatch(fetchTranslationsSuccess(body)),
          () => dispatch(fetchTranslationsFailure()),
        )
        .then(() => translationsPromise);
    };
  };

export const fetchTranslations = createFetchTranslations(
  fetchTranslationsAction,
);
export const handleTranslationsResponse = createFetchTranslations(
  handleAggregatorResponse,
);
