export const getMultilingualQueryParam = (multilingual) => {
  if (multilingual?.currentLanguage) {
    const locale = multilingual.siteLanguages.find(
      (lang) => lang.languageCode === multilingual.currentLanguage,
    );
    if (!locale.isPrimaryLanguage && locale.resolutionMethod === 'QueryParam') {
      return `?lang=${locale.languageCode}`;
    }
  }
  return '';
};
