import {
  SECTION_CATEGORY,
  getLastPage,
} from '@wix/communities-blog-client-common';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';

import { getSectionUrl } from '../../store/topology/topology-selectors';
import { getHashtagUrl } from '../get-hashtag-url';
import { getPaginationUrls } from './get-pagination-item-data';
import { createHashtagPageUrl } from '../navigation';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import { getMultilingualQueryParam } from '../get-multilingual-query-param';

export const generateHashtagSEOTags = ({
  state,
  hashtag,
  page = 1,
  posts,
  multilingual,
}) => {
  const sectionUrl = getSectionUrl(state);

  const hashtagUrl = getHashtagUrl(sectionUrl, hashtag);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const multilingualQueryParam = getMultilingualQueryParam(multilingual);
  const pagination = {
    ...getPaginationUrls(
      hashtagUrl,
      page,
      lastPage,
      state,
      multilingualQueryParam,
    ),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    title: `#${hashtag}`,
    label: hashtag,
    url: createHashtagPageUrl(
      sectionUrl,
      hashtag,
      page,
      multilingualQueryParam,
    ),
    items: {
      numberOfItems: posts.length,
    },
    pagination,
    blogFeedRoute: sectionUrl,
  };

  return {
    itemType: ITEM_TYPES.BLOG_HASHTAGS,
    itemData: {
      hashtag: itemData,
    },
    asNewPage: true,
  };
};
