import {
  ENTITY_TYPE_POSTS,
  fetchAppData,
  fetchTagsSuccess,
  fetchTPASettingsSuccess,
  getCategoryIds,
  getLocales,
  getDefaultSiteLocale,
} from '@wix/communities-blog-client-common';

import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import { fetchPaywallBanner } from '../../common/store/paywall/paywall-actions';
import { fetchTranslationsSuccess } from '../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { renderModel } from '../api/feed.api';
import { fetchFeedPostsSuccess } from '../../common/actions/fetch-feed-posts';
import { normalizePosts } from '../../common/services/post-utils';

export const fetchFeedRenderModel = ({ language, translationsName, useICU }) => async (dispatch, getState, { httpClient }) => {
  const state = getState();

  const locale = getQueryLocale(state);
  const languageCode = locale || language || (getLocales(state) && getDefaultSiteLocale(state)?.id || null);

  const { data } = await httpClient.request(renderModel({
    locale,
    languageCode,
    translationsName,
  }));
  const { settings, categories, tags, translations, posts } = data;

  //TODO: refactor consumer to fallback on label when menuLabel is not available
  categories.forEach(x => {
    x.menuLabel = x.label;
  })

  console.log('## fetchFeedRenderModel', { settings, categories, tags, translations, posts });

  return Promise.all([
    dispatch(fetchTPASettingsSuccess(settings)),
    dispatch(fetchCategoriesSuccess(categories)),
    dispatch(fetchPaywallBanner()),
    dispatch(fetchAppData()),
    dispatch(fetchTranslationsSuccess(translations)),
    dispatch(fetchTagsSuccess(tags)),
    dispatch(
        fetchFeedPostsSuccess(
          normalizePosts({
            state: { categories, translations },
            posts: posts.posts,
            blogCategoryIds: getCategoryIds({ categories }),
            origin: '/v3/posts',
          }),
          {
            page: 1,
            entityType: ENTITY_TYPE_POSTS,
            entityCount: posts.metaData?.total,
            pageSize: 3,
          },
        ),
    ),
  ]);
};
