import {
  fetchAppData,
  fetchTPASettings,
} from '@wix/communities-blog-client-common';

import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { getInstance } from '../../common/controller/helpers';
import { fetchCategoryWidgetInitialData } from '../actions/fetch-initial-data';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getIsICUBaseUrlEnabled } from '../../common/selectors/icu-selectors';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));
  const useICU = getIsICUBaseUrlEnabled(appParams);

  await Promise.all([
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(fetchAppData()), // TODO: add to frontend adapter
    store.dispatch(
      fetchTPASettings({
        language: getQueryLocale(store.getState()) || language,
      }),
    ), // TODO: add to frontend adapter
    store.dispatch(fetchCategoryWidgetInitialData({ useICU })),
  ]);

  await store.dispatch(fetchTopology(instanceId));
}
