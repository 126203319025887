import { __assign, __awaiter, __generator } from "tslib";
import { get, noop, debounce, invoke } from 'lodash';
var trackers = {};
var noopTracker = {
    trackStart: noop,
    trackEnd: noop,
    trackPromise: function (_, fn) { return fn(); },
    performanceEntries: {},
    parseHeader: noop,
    isNoop: true,
};
var performanceNow = function () { return performance.now(); };
export var createPerformanceTracker = function (scope, _a, now) {
    var _b = _a === void 0 ? {} : _a, isDebug = _b.isDebug, isSSR = _b.isSSR;
    if (now === void 0) { now = performanceNow; }
    if (!scope || !isDebug || isSSR || typeof performance === 'undefined') {
        return noopTracker;
    }
    if (!trackers[scope]) {
        var perfSubEntries_1 = {};
        var perfParents_1 = {};
        var tracker_1 = {
            performanceEntries: {},
            trackStart: function (marker, parentMarker) {
                if (parentMarker) {
                    perfParents_1[marker] = parentMarker;
                }
                var entries = parentMarker
                    ? (perfSubEntries_1[parentMarker] = perfSubEntries_1[parentMarker] || {})
                    : tracker_1.performanceEntries;
                entries[marker] = now();
                return marker;
            },
            trackEnd: function (marker, subValues) {
                if (subValues === void 0) { subValues = []; }
                if (perfSubEntries_1[marker]) {
                    subValues = Object.entries(perfSubEntries_1[marker])
                        .map(function (_a) {
                        var key = _a[0], props = _a[1];
                        return (__assign({ key: key }, props));
                    })
                        .concat(subValues);
                    delete perfSubEntries_1[marker];
                }
                var parentMarker = perfParents_1[marker];
                var entries = parentMarker ? perfSubEntries_1[parentMarker] : tracker_1.performanceEntries;
                var value = now() - entries[marker];
                entries[marker] = Array.isArray(subValues) && subValues.length ? { value: value, subValues: subValues } : { value: value };
            },
            trackPromise: function (marker, promiseFn, parentMarker) { return __awaiter(void 0, void 0, void 0, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            tracker_1.trackStart(marker, parentMarker);
                            return [4 /*yield*/, promiseFn()];
                        case 1:
                            result = _a.sent();
                            tracker_1.trackEnd(marker);
                            return [2 /*return*/, result];
                    }
                });
            }); },
            reset: function () {
                tracker_1.performanceEntries = {};
            },
            parseHeader: parseServerTimingHeader,
        };
        trackers[scope] = tracker_1;
    }
    return trackers[scope];
};
export var getMetrics = function (scope) {
    return scope
        ? get(trackers, [scope, 'performanceEntries'], {})
        : Object.keys(trackers).reduce(function (acc, x) {
            acc[x] = trackers[x].performanceEntries;
            return acc;
        }, {});
};
var _shouldLog = true;
export var resetMetrics = function (scope) {
    _shouldLog = true;
    invoke(trackers[scope], 'reset');
};
export var logMetrics = debounce(function () {
    _shouldLog && console.log('Metrics', getMetrics());
    _shouldLog = false;
}, 5000);
function parseServerTimingHeader(response) {
    if (!response || !response.headers) {
        return [];
    }
    var serverTiming = response.headers.get('server-timing');
    if (!serverTiming) {
        return [];
    }
    return serverTiming.split(',').reduce(function (acc, x) {
        var match = x.match(/([^;]+);dur=([\d.]+)/);
        if (match) {
            acc.push({ key: match[1], value: Number(match[2]) });
        }
        return acc;
    }, []);
}
