import { omit } from 'lodash';
export var UPDATE_BLOG_DATA_REQUEST = 'appData/UPDATE_REQUEST';
export var UPDATE_BLOG_DATA_SUCCESS = 'appData/UPDATE_SUCCESS';
export var UPDATE_BLOG_DATA_FAILURE = 'appData/UPDATE_FAILURE';
export var updateAppDataRequest = function () { return ({ type: UPDATE_BLOG_DATA_REQUEST }); };
export var updateAppDataSuccess = function (payload) { return ({ type: UPDATE_BLOG_DATA_SUCCESS, payload: payload }); };
export var updateAppDataFailure = function () { return ({ type: UPDATE_BLOG_DATA_FAILURE }); };
export function updateAppData(data) {
    if (data === void 0) { data = {}; }
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(updateAppDataRequest());
        var promise = request.patch('/_api/blogs/this', omit(data, ['_id', 'communityId']));
        return promise
            .then(function (post) { return dispatch(updateAppDataSuccess(post)); })
            .catch(function () { return dispatch(updateAppDataFailure()); })
            .then(function () { return promise; });
    };
}
