var createResponseHandler = function (_a) {
    var _b = (_a === void 0 ? {} : _a).withHeaders, withHeaders = _b === void 0 ? false : _b;
    return function (response) { return function () {
        if (response.status < 300) {
            return withHeaders
                ? Promise.resolve({ body: response.body, headers: new Headers(response.headers) })
                : Promise.resolve(response.body);
        }
        return Promise.reject(response);
    }; };
};
export var handleAggregatorResponse = createResponseHandler({ withHeaders: false });
export var handleAggregatorResponseWithHeaders = createResponseHandler({ withHeaders: true });
