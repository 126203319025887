export var FETCH_TAG_REQUEST = 'tag/FETCH_REQUEST';
export var FETCH_TAG_SUCCESS = 'tag/FETCH_SUCCESS';
export var FETCH_TAG_FAILURE = 'tag/FETCH_FAILURE';
export var fetchTagSuccess = function (payload) { return ({ type: FETCH_TAG_SUCCESS, payload: payload }); };
export function fetchTag(tagSlug, language) {
    return function (dispatch, getState, _a) {
        var request = _a.request;
        var filter = { slug: { $eq: tagSlug } };
        if (language) {
            filter = {
                $and: [
                    { language: { $eq: language } },
                    filter,
                ]
            };
        }
        return request
            .post('/v2/tags/query', { filter: filter })
            .then(function (_a) {
            var tags = _a.tags;
            if (tags.length) {
                dispatch(fetchTagSuccess(tags[0]));
                return tags[0];
            }
            return Promise.reject({ status: 404 });
        });
    };
}
