import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleTagCloudWidgetResponse } from '../aggregated-tag-cloud/actions';

export const fetchTagCloudWidgetInitialData =
  ({ useICU } = {}) =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const langQuery = getQueryLocale(getState());
    const language = langQuery ? `&language=${langQuery}` : '';
    const icu = useICU ? '&icu=true' : '';

    const { tagCloud } = await aggregatorRequest(
      `/v1/tag-cloud-widget/render-model?onlyWithPublishedPosts=true${language}${icu}`,
      {
        throwOnInvalidJson: true,
      },
    );

    await dispatch(handleTagCloudWidgetResponse(tagCloud));
  };
