import { trimEnd } from 'lodash';
import { POST_SEO_DESCRIPTION_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';
import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { limitLength, removeTrailingSlash } from '../services/utils';
export var getPostSeoDescription = function (_a) {
    var seoDescription = _a.seoDescription, content = _a.content, excerpt = _a.excerpt;
    return limitLength(seoDescription || excerpt || getContentText(content, POST_SEO_DESCRIPTION_MAXLENGTH), POST_SEO_DESCRIPTION_MAXLENGTH);
};
export var getPostAmpUrl = function (postId, ampBaseUrl, postSlug) {
    if (!ampBaseUrl || !postId)
        return;
    return removeTrailingSlash(ampBaseUrl) + "/" + removeTrailingSlash(postSlug);
};
export var getCanonicalPostUrl = function (_a) {
    var post = _a.post, postPageSectionUrl = _a.postPageSectionUrl, customPostUrl = _a.customPostUrl;
    if (customPostUrl) {
        return customPostUrl;
    }
    if (post.url) {
        return "" + post.url.base + post.url.path;
    }
    if (post.canonicalUrl) {
        return post.canonicalUrl;
    }
    return trimEnd(postPageSectionUrl, '/') + "/" + post.slug;
};
