export var FETCH_BLOG_DATA_REQUEST = 'appData/FETCH_REQUEST';
export var FETCH_BLOG_DATA_SUCCESS = 'appData/FETCH_SUCCESS';
export var FETCH_BLOG_DATA_FAILURE = 'appData/FETCH_FAILURE';
export var fetchAppDataRequest = function () { return ({ type: FETCH_BLOG_DATA_REQUEST }); };
export var fetchAppDataSuccess = function (payload) { return ({ type: FETCH_BLOG_DATA_SUCCESS, payload: payload }); };
export var fetchAppDataFailure = function () { return ({ type: FETCH_BLOG_DATA_FAILURE }); };
export function fetchAppData(promise) {
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(fetchAppDataRequest());
        promise =
            promise || request.metered
                ? request.metered('/_api/blogs/this')('/_api/blogs/this')
                : request('/_api/blogs/this');
        return promise
            .then(function (appData) { return dispatch(fetchAppDataSuccess(appData)); }, function () { return dispatch(fetchAppDataFailure()); })
            .then(function () { return promise; });
    };
}
