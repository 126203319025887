import { get } from 'lodash';
import { createAction, urijs } from '@wix/communities-blog-client-common';
import { getInstance, isFrameless } from '../../controller/helpers';
import getEnvironment from '../../services/get-environment';
import { getCurrentPage } from '../../services/get-current-page';
import { isRtlLanguage } from '../../services/is-rtl-language';
import { resolveDeviceType } from '../../services/device-type';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { getIsICUBaseUrlEnabled } from '../../selectors/icu-selectors';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
export const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export const SET_INSTANCE = 'SET_INSTANCE';
export const setInstance = createAction(SET_INSTANCE);

export const setBasicParams =
  ({ viewMode, language, biPageNumber }) =>
  async (dispatch, getState, { wixCodeApi, appParams }) =>
    dispatch(
      setBasicParamsAction(
        await resolveBasicParams({
          viewMode,
          language,
          appParams,
          biPageNumber,
          wixCodeApi,
          getState,
        }),
      ),
    );

export const resolveBasicParams = async ({
  viewMode,
  language,
  appParams,
  biPageNumber,
  wixCodeApi,
  getState,
}) => {
  const showMobileView =
    wixCodeApi.location.url &&
    urijs(wixCodeApi.location.url).hasQuery('showMobileView', 'true');
  const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(
    getState(),
  );
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);
  const isRTL = isRtlLanguage(language);
  const useICU = getIsICUBaseUrlEnabled(appParams);
  let currentPage = wixCodeApi.site.currentPage;
  if (!isFrameless({ state: getState(), wixCodeApi })) {
    currentPage = await getCurrentPage();
    currentPage.name = currentPage.title;
  }

  const apiBaseUrl = isSSR
    ? appParams.baseUrls.apiBaseUrl
    : appParams.baseUrls.apiBaseUrlClient;
  return {
    viewMode,
    instance: getInstance(wixCodeApi)(),
    formFactor: showMobileView
      ? 'Mobile'
      : resolveDeviceType(wixCodeApi, isCreatedWithResponsiveEditor),
    isSSR,
    isRTL,
    isSeo: wixCodeApi.seo.isInSEO(),
    language,
    apiBaseUrl,
    isDebug,
    isProduction: process.env.NODE_ENV === 'production',
    biPageNumber,
    page: wixCodeApi.location.query.page,
    commentId: get(wixCodeApi, 'location.query.commentId'),
    url: wixCodeApi.location.url,
    currentPage,
    timeZone: get(wixCodeApi, 'site.timezone'),
    useICU,
  };
};
